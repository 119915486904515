import { FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ColumnDef } from "@tanstack/react-table"
import Tippy from "@tippyjs/react"
import { Card } from "components/card/card"
import { Header } from "components/header/header"
import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import { RootDispatch, RootState } from "store"
import { abortController } from "api/api"
import { useModal } from "context/modal-context"
import { ConfirmationModal } from "common/modals/confirmation-modal/confirmation-modal"
import { SortDirection } from "common/enums/SortDirection.enum"
import { AssetModel } from "common/models/AssetModel"
import { ExportRequestOptions } from "../../common/models/ExportRequestOptions"
import { gridPageSizes } from "../../utils/constants"
import { PageModel } from "../../common/models/PageModel"
import { AssetType } from "common/enums/AssetType.enum"
import { ExportModel } from "common/models/ExportModel"
import { PaginatedTable } from "components/paginated-table/paginated-table"
import { toUTC } from "utils"
import "./downloads.scss"

const DOWNLOADS_GRID_ID = "downloadsDiv"

type RequestChangeParams = {
  page?: number
  pageSize?: number
  column?: any
}

export const DownloadsComponent: FC = () => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal } = useModal()
  const [downloadsLoading, setDownloadsLoading] = useState<boolean>(false)
  const [hasConfirmFailed, setHasConfirmFailed] = useState<boolean>(false)
  const downloads = useSelector((state: RootState) => state.exports.downloads)
  const requestOptions = useSelector((state: RootState) => state.exports.requestOptions)
  const totalItemsCount = useSelector((state: RootState) => state.exports.totalItemsCount)
  const isViewer = useSelector(
    (state: RootState) => state.workspaces.isSelectedWorkspaceViewer,
  )
  const currentUser = useSelector((state: RootState) => state.account.user)

  const getWorkspaceExportsPaged = dispatch.exports.getWorkspaceExportsPaged
  const setRequestOptions = dispatch.exports.setRequestOptions
  const deleteExport = dispatch.exports.deleteExport
  const deleteExpandableExport = dispatch.exports.deleteExpandableExport
  const getExportsCount = dispatch.exports.getExportsCount
  const downloadExport = dispatch.exports.downloadExport

  const {
    pageModel: { page, pageSize, sortField, sortDirection },
  } = requestOptions
  const currentUserFullName = currentUser.firstName + " " + currentUser.lastName

  useEffect(() => {
    setDownloadsLoading(true)
    getWorkspaceExportsPaged(requestOptions).then(() => setDownloadsLoading(false))
  }, [requestOptions])

  useEffect(() => {
    getExportsCount()

    return () => {
      setRequestOptions(
        new ExportRequestOptions({
          pageModel: new PageModel({
            page: 1,
            pageSize: 10,
            sortField: "createdAt",
            sortDirection: SortDirection.Descending,
          }),
        }),
      )
      abortController.abort()
    }
  }, [])

  const exportDownload = async (item: ExportModel | AssetModel) => {
    await downloadExport({
      exportId: "id" in item ? item.id : item.exportId,
      blobName: "asset" in item ? item.asset?.blobName ?? "" : item.blobName,
    }).then((response) => {
      if (response) {
        window.location.href = response.downloadUrl || ""
      }
    })
  }

  const exportMultipleDownloads = async (items: ExportModel[] | AssetModel[]) => {
    const downloadPromises = items.map((item) =>
      downloadExport({
        exportId: "id" in item ? item.id : item.exportId,
        blobName: "asset" in item ? item.asset?.blobName ?? "" : item.blobName,
      }),
    )

    const responses = await Promise.all(downloadPromises)
    if (responses) {
      responses
        ?.filter((response): response is { downloadUrl: string } => response !== false)
        .forEach((response) => {
          const link = document.createElement("a")
          link.href = response.downloadUrl
          link.download = ""
          link.target = "_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    }
  }

  const onDelete = (item: ExportModel | AssetModel, deleteAll: boolean) => {
    let newPage =
      totalItemsCount - 1 > (Number(page) - 1) * Number(pageSize)
        ? page
        : Number(page) - 1
    newPage = newPage === 0 ? 1 : newPage
    const getDeleteApi = (deleteAll: boolean): Promise<boolean> => {
      if (deleteAll) {
        return deleteExpandableExport({
          exportId: "id" in item ? item.id : item.exportId,
          requestOptions: new ExportRequestOptions({
            pageModel: {
              ...requestOptions.pageModel,
              page: newPage,
            },
          }),
        })
      } else {
        return deleteExport({
          exportId: "id" in item ? item.id : item.exportId,
          blobName: "asset" in item ? item.asset?.blobName ?? "" : item.blobName,
          requestOptions: new ExportRequestOptions({
            pageModel: {
              ...requestOptions.pageModel,
              page: newPage,
            },
          }),
        })
      }
    }
    return setModal(
      <ConfirmationModal
        onConfirm={() => {
          getDeleteApi(deleteAll).then((response) => {
            if (!response) {
              setHasConfirmFailed(true)
            }
          })
        }}
        hasConfirmFailed={hasConfirmFailed}
        setHasConfirmedFailed={setHasConfirmFailed}
        title=""
        confirmLabel="Yes, Delete it"
        variant="danger"
      >
        <div className="flex flex-col items-center gap-6 max-w-md">
          <Icon
            icon={ICONS.DELETE}
            className="text-red-600 justify-self-center"
            size={10}
          />
          <span className="text-title-3 font-bold text-gray-900 text-center mb-6">
            Are you sure you want to delete this document?
          </span>
        </div>
      </ConfirmationModal>,
    )
  }

  const onRequestParamsChange = ({ page, pageSize, column }: RequestChangeParams) =>
    setRequestOptions(
      new ExportRequestOptions({
        pageModel: Object.assign(
          {
            ...requestOptions.pageModel,
          },
          !!page && { page },
          !!pageSize && { pageSize },
          !!column && {
            sortField: column.accessorKey === "time" ? "createdAt" : column.accessorKey,
            sortDirection:
              sortDirection === SortDirection.Ascending
                ? SortDirection.Descending
                : SortDirection.Ascending,
          },
        ),
      }),
    )

  const tooltip = (exportedColumns: string[], numberOfColumns: number) => (
    <Tippy
      maxWidth="80vw"
      placement="right"
      interactive
      appendTo={document.body}
      content={
        <div
          className="grid gap-2 rounded-md bg-white shadow-lg p-4"
          style={{
            gridTemplateColumns: `repeat(${Math.ceil(
              numberOfColumns,
            )}, minmax(100px, max-content))`,
          }}
        >
          <p className="text-black font-medium text-caption-1 mb-1 col-span-full">
            Columns in Export
          </p>
          {exportedColumns.map((col: string) => (
            <p className="text-gray-500 font-normal text-caption-1">• {col}</p>
          ))}
        </div>
      }
    >
      <span className="flex items-center mr-5">
        <Icon icon={ICONS.INFORMATION_CIRCLE_OUTLINED} size={5} />
      </span>
    </Tippy>
  )

  const downloadsColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "fileName",
        size: 300,
        cell: ({ row, getValue }: any) => {
          return (
            <div
              style={{
                paddingLeft: row.getCanExpand() === true ? `${row.depth * 60}px` : "54px",
              }}
              className="flex items-center justify-left gap-2.5 relative"
            >
              {row.getCanExpand() === true && (
                <button
                  type="button"
                  className="flex items-center justify-center mr-2.5 w-[34px] h-[34px] rounded-full text-gray-500 hover:bg-gray-100"
                  onClick={row.getToggleExpandedHandler()}
                >
                  <Icon icon={row.getIsExpanded() ? ICONS.MINUS : ICONS.PLUS} />
                </button>
              )}
              {row.depth > 0 && (
                <Icon className="text-gray-300" icon={ICONS.ARROW_RIGHT} />
              )}
              <Icon icon={ICONS.DOCUMENT_TEXT} />
              {getValue()}
            </div>
          )
        },
        footer: (props: any) => props.column.id,
      },
      {
        header: "Exported From",
        accessorKey: "assetType",
        cell: ({ row }: any) => {
          if (row.depth > 0) {
            return null
          }

          return (
            <span>
              {row.original.assetType === AssetType.ReportExport ||
              row.original.assetType === AssetType.TrendScoreReportExport
                ? "Reports"
                : "Catalog"}
            </span>
          )
        },
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        cell: ({ row }: any) => {
          if (row.depth > 0) {
            return null
          }
          return toUTC(row.original.createdAt)
        },
      },
      {
        header: "Time",
        accessorKey: "time",
        cell: ({ row }: any) => {
          if (row.depth > 0) {
            return null
          }
          return <>{new Date(row.original.createdAt).toLocaleTimeString()}</>
        },
      },
      {
        header: "Generated By",
        accessorKey: "createdBy",
        cell: ({ row, getValue }: any) => {
          if (row.depth > 0) {
            return null
          }
          return getValue()
        },
      },
      {
        header: "Actions",
        accessorKey: "actions",
        minSize: 100,
        cell: ({ row }: any) => {
          const isDeleteDisabled =
            isViewer || currentUserFullName !== row.original.createdBy
          const exportedColumnsLength = row.original.exportedColumns?.length
          const numberOfColumns =
            exportedColumnsLength <= 10
              ? 1
              : exportedColumnsLength > 50
              ? exportedColumnsLength / 18
              : 2
          if (row.getCanExpand() === true) {
            return (
              <div className="flex justify-between items-center">
                <div>
                  <Button
                    tippyContent="Download"
                    className="download"
                    variant="icon-btn"
                    onClick={() => exportMultipleDownloads(row.originalSubRows)}
                    isActionButton={true}
                  >
                    <Icon className="text-gray-500" icon={ICONS.DOWNLOAD} />
                  </Button>
                  <Button
                    tippyContent="Delete"
                    className="delete mr-2"
                    variant="icon-btn"
                    onClick={() => onDelete(row.original, true)}
                    disabled={isDeleteDisabled}
                    isActionButton={true}
                  >
                    <Icon
                      className={`${
                        isDeleteDisabled ? "text-gray-300" : "text-gray-500"
                      }`}
                      icon={ICONS.DELETE}
                    />
                  </Button>
                </div>
                {row.original.exportedColumns &&
                  exportedColumnsLength > 0 &&
                  tooltip(row.original.exportedColumns, numberOfColumns)}
              </div>
            )
          }
          return (
            <div className="flex justify-between items-center">
              <div>
                <Button
                  tippyContent="Download"
                  className="download"
                  variant="icon-btn"
                  onClick={() => exportDownload(row.original)}
                  isActionButton={true}
                >
                  <Icon className="text-gray-500" icon={ICONS.DOWNLOAD} />
                </Button>
                <Button
                  tippyContent="Delete"
                  className="delete"
                  variant="icon-btn"
                  onClick={() => onDelete(row.original, false)}
                  disabled={isDeleteDisabled}
                  isActionButton={true}
                >
                  <Icon
                    className={`${isDeleteDisabled ? "text-gray-300" : "text-gray-500"}`}
                    icon={ICONS.DELETE}
                  />
                </Button>
              </div>
              {row.original.exportedColumns &&
                exportedColumnsLength > 0 &&
                tooltip(row.original.exportedColumns, numberOfColumns)}
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <div className="downloads">
      <Header title="Downloads" />
      <Card>
        <PaginatedTable
          resizable
          columns={downloadsColumns}
          data={downloads}
          maxHeight="calc(100vh - 330px)"
          rowHeight={48}
          expandable
          customExpand
          getSubRows={(row: any) => row.assets}
          onSortChange={(column) => onRequestParamsChange({ column })}
          loading={downloadsLoading}
          emptyTableMessage="No Downloads found."
          emptyTableLink={{ label: "Create a New Export.", route: "/main/audit-catalog" }}
          sortField={sortField}
          sortDirection={sortDirection}
          scrollableDivId={DOWNLOADS_GRID_ID}
          page={page}
          onPageChange={onRequestParamsChange}
          pageSize={pageSize}
          onPageSizeChange={onRequestParamsChange}
          totalCount={totalItemsCount}
          pageSizeOptions={gridPageSizes}
        />
      </Card>
    </div>
  )
}
