import { RubricDetailsModel } from "common/models/RubricDetailsModel"
import { RubricCreationModel } from "common/models/RubricCreationModel"
import { RubricModel } from "common/models/RubricModel"
import { ItemsCountModel } from "common/models/ItemsCountModel"
import { RubricModelPageResultModel } from "common/models/RubricModelPageResultModel"
import { RetailerRubricModel } from "common/models/RetailerRubricModel"
import { RetailerRubricAllocationModel } from "common/models/RetailerRubricAllocationModel"
import { API } from "../api"
import { RetailerModel } from "common/models/RetailerModel"
import { RubricsGetModel } from "common/models/RubricsGetModel"
import { QualityRuleModel } from "common/models/QualityRuleModel"

export const getRubricApi = (rubricId: string): Promise<RubricDetailsModel> =>
  API().get(`rubrics/${rubricId}`)

export const createRubricApi = (
  workspaceId: string,
  body: RubricCreationModel,
): Promise<RubricDetailsModel> => API().post(`workspaces/${workspaceId}/rubrics`, body)

export const getWorkspaceRubricsApi = (workspaceId: string): Promise<RubricModel[]> =>
  API().get(`workspaces/${workspaceId}/rubrics`)

export const saveRubricChagesApi = (
  workspaceId: string,
  rubricId: string,
  body: RubricCreationModel,
): Promise<RubricDetailsModel> =>
  API().put(`workspaces/${workspaceId}/rubrics/${rubricId}`, body)

export const deleteRubricApi = (
  workspaceId: string,
  rubricId: string,
): Promise<boolean> => API().delete(`workspaces/${workspaceId}/rubrics/${rubricId}`)

export const getWorkspaceRubricsApiPaged = (
  workspaceId: string,
  body: RubricsGetModel,
): Promise<RubricModelPageResultModel> =>
  API().post(`workspaces/${workspaceId}/rubrics/paged/new`, body)

export const getWorkspaceRubricsCountApi = (
  workspaceId: string,
): Promise<ItemsCountModel[]> => API().post(`workspaces/${workspaceId}/rubrics/count`)

export const getDefaultRubricsApi = (
  workspaceId: string,
): Promise<RetailerRubricModel[]> =>
  API().get(`workspaces/${workspaceId}/rubrics/defaultRubrics`)

export const getNewDefaultRubricsApi = (ruleType: number): Promise<RubricDetailsModel> =>
  API().get(`rubrics/default/newRubric`, {params: {analysisType: ruleType}})

export const updateDefaultRubricApi = (
  workspaceId: string,
  body: RetailerRubricAllocationModel[],
): Promise<RetailerRubricAllocationModel[]> =>
  API().put(`workspaces/${workspaceId}/rubrics/setdefault`, body)

export const deleteRetailerAllocationApi = (
  workspaceId: string,
  allocationId: string,
): Promise<boolean> =>
  API().delete(`workspaces/${workspaceId}/rubrics/allocations/${allocationId}`)

export const deleteRetailerAllocationByIdListApi = (
  workspaceId: string,
  body: string[],
): Promise<boolean> =>
  API().post(`workspaces/${workspaceId}/rubrics/allocations/remove`, body)

export const getAllocatedRetailersApi = (
  workspaceId: string,
  rubricId: string,
): Promise<{
  activeRetailers: RetailerModel[]
  notUsedRetailers: RetailerModel[]
}> => API().get(`workspaces/${workspaceId}/rubrics/${rubricId}/allocatedRetailers`)

export const getQualityRules = (workspaceId: string, identifier: string): Promise<QualityRuleModel[]> =>
  API().get(`workspaces/${workspaceId}/rubrics/quality-rules`, {params: {ruleIdentifier: identifier}})